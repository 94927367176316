import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Blog from "../components/Blog";
import * as styles from "./index.module.css";

const Eat = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location}>
      <Seo title="Food" />
      <div className={styles.outerContainer}>
        <div className={styles.travelContainer}>
          <h1 className={styles.pageHeader}>Food</h1>

          <Blog posts={posts} />
        </div>
      </div>
    </Layout>
  );
};

export default Eat;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { categories: { eq: "eat" } } }) {
      nodes {
        excerpt
        fields {
          slug
        }
        id
        frontmatter {
          categories
          date(formatString: "MMMM DD, YYYY")
          description
          title
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
